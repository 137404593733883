<template>
  <div class="container-page big">
    <h2 class="title title--h2">
      {{ isRG ? 'Карточка РГ' : 'Карточка МПК' }}
    </h2>

    <Tab class="tab-redesign">
      <TabItem name="Основная информация" :selected="true">
        <!-- формы для создания -->
        <form action="/" class="form-card" v-if="isRG">
          <div class="coll coll-12" v-if="!isEdit">
            <h3 class="title title--h3 title--commission">Место и название</h3>
          </div>
          <div class="coll coll-inner coll-12">
            <div class="coll coll-inner coll-6 coll-t-12">
              <div v-if="!isEdit && !isReadonly" class="coll coll-12 m-24">
                <div class="label-field">Страна <span>*</span></div>
                <SelectMultiComponent
                  v-model="item.countryId"
                  :options="countryList"
                  placeholder="Выберите"
                  modifier="rectangular"
                  @select="changeCountry"
                  required
                />
                <div v-if="errorCountryId" class="invalid-msg">Поле обязательно для заполнения</div>
              </div>
              <div class="coll coll-12 m-24" v-if="isEdit || isReadonly">
                <div class="label-field">Страна <span>*</span></div>
                <div class="form-card__country">
                  <div
                    class="form-card__country-img"
                    v-if="item.country?.picture"
                    :style="{ 'background-image': `url(${item.country?.picture})` }"
                  ></div>
                  {{ item.country?.name }}
                </div>
              </div>
              <div class="coll coll-12 m-24">
                <ComponentInput
                  label="Полное название РГ <span>*</span>"
                  v-model="item.name"
                  required
                  maxLength="500"
                  type="textarea"
                  placeholder="Введите"
                  mod="rectangular"
                  :disabled="isReadonly"
                />
              </div>
              <div class="coll coll-12 m-24">
                <div class="label-field">Статус <span>*</span></div>
                <SelectMultiComponent
                  v-model="item.statusId"
                  :options="statusList"
                  placeholder="Выберите"
                  modifier="rectangular"
                  :disabled="isReadonly"
                  required
                />
                <div v-if="errorStatusId" class="invalid-msg">Поле обязательно для заполнения</div>
              </div>
              <div class="coll coll-12 m-24" v-if="!isEdit && !isReadonly">
                <div class="label-field">Межправительственная комиссия <span>*</span></div>
                <SelectMultiComponent
                  v-model="item.parentId"
                  :options="parentList"
                  placeholder="Выберите"
                  modifier="rectangular"
                  :disabled="isReadonly"
                  required
                />
                <div v-if="errorParentId" class="invalid-msg">Поле обязательно для заполнения</div>
              </div>
              <div class="coll coll-12 m-24" v-if="isEdit || isReadonly">
                <div class="label-field">Межправительственная комиссия <span>*</span></div>
                <p class="form-card__parent">{{ item.parentName }}</p>
              </div>
              <div class="coll coll-12 m-24">
                <div class="label-field">ФОИВ куратор</div>
                <SelectMultiComponent
                  v-model="item.foivCuratorId"
                  :options="foivList"
                  placeholder="Выберите"
                  modifier="rectangular"
                  :disabled="isReadonly"
                />
              </div>
            </div>
          </div>
          <div class="form-card__inner-card" v-if="item.countryId > 0">
            <div class="coll coll-6 coll-t-12 m-24">
              <CommissionParticipantBlock
                v-model="chairman"
                v-bind:isReadonly="isReadonly"
                v-bind:participantCategoryId="1"
                :isRG="isRG"
                v-bind:participantCategoryName="getParticipantCategoryName(1)"
                v-bind:countryId="countryRussiaId"
                v-bind:countryName="countryRussiaName"
                :showPhoto="true"
              />
            </div>
            <div class="coll coll-6 coll-t-12 m-24">
              <CommissionParticipantBlock
                v-model="chairmanForeign"
                v-bind:isReadonly="isReadonly"
                v-bind:participantCategoryId="1"
                :isRG="isRG"
                v-bind:participantCategoryName="getParticipantCategoryName(1)"
                v-bind:countryId="item.countryId"
                v-bind:countryName="getCountryName(item.countryId)"
                :showPhoto="true"
              />
            </div>
          </div>

          <div v-if="isEdit" class="coll coll-12">
            <ButtonComponent
              @click.prevent.stop="save"
              mod="gradient-bg"
              class="button-redesign--map-apply button-redesign--commission-form-edit"
              >Сохранить</ButtonComponent
            >
            <ButtonComponent @click.prevent.stop="close" class="button-redesign--commission-form-edit"
              >Отменить</ButtonComponent
            >
          </div>

          <div v-if="!isEdit" class="coll coll-inner coll-12">
            <div class="coll coll-inner coll-6 coll-m-12">
              <div class="coll coll-6 coll-m-12 m-24">
                <ButtonComponent
                  @click.prevent.stop="save"
                  mod="gradient-bg"
                  class="button-redesign--map-apply button-redesign--commission-form"
                  >Сохранить</ButtonComponent
                >
              </div>
              <div class="coll coll-6 coll-m-12 m-24">
                <ButtonComponent @click.prevent.stop="close" class="button-redesign--commission-form"
                  >Отменить</ButtonComponent
                >
              </div>
            </div>
          </div>
        </form>

        <form action="/" class="form-card" v-else>
          <div class="coll coll-12 m-24" v-if="!isEdit">
            <h3 class="title title--h3 title--commission">Место и название</h3>
          </div>
          <div class="coll coll-inner coll-12">
            <div class="coll coll-inner coll-6 coll-t-12">
              <div class="coll coll-12 m-24" v-if="!isEdit && !isReadonly">
                <div class="label-field">Страна <span>*</span></div>
                <SelectMultiComponent
                  v-model="item.countryId"
                  :options="countryList"
                  placeholder="Выберите"
                  modifier="rectangular"
                  @select="changeCountry"
                  required
                />
                <div v-if="errorCountryId" class="invalid-msg">Поле обязательно для заполнения</div>
              </div>
              <div class="coll coll-12 m-24" v-if="isEdit || isReadonly">
                <div class="label-field">Страна <span>*</span></div>
                <div class="form-card__country">
                  <div
                    class="form-card__country-img"
                    v-if="item.country?.picture"
                    :style="{ 'background-image': `url(${item.country?.picture})` }"
                  ></div>
                  {{ item.country?.name }}
                </div>
              </div>
              <template v-if="!isEdit && !isReadonly">
                <div class="coll coll-12 m-24">
                  <dateComponent
                    label="Дата создания"
                    v-model="item.createdInfo"
                    modPicker="rectangular"
                    :clearable="false"
                    :dateIcon="true"
                    modClass="modal-request"
                    :disabled="isReadonly"
                  />
                </div>
                <div class="coll coll-12 m-24">
                  <div class="label-field">Статус <span>*</span></div>
                  <SelectMultiComponent
                    v-model="item.statusId"
                    :options="statusList"
                    placeholder="Выберите"
                    modifier="rectangular"
                    required
                  />
                  <div v-if="errorStatusId" class="invalid-msg">Поле обязательно для заполнения</div>
                </div>
                <div class="coll coll-12 m-24">
                  <ComponentInput
                    label="Полное название комиссии <span>*</span>"
                    v-model="item.name"
                    required
                    maxLength="500"
                    type="textarea"
                    placeholder="Введите"
                    mod="rectangular"
                  />
                </div>
              </template>
              <template v-else>
                <div class="coll coll-12 m-24">
                  <dateComponent
                    label="Дата создания"
                    v-model="item.createdInfo"
                    modPicker="rectangular"
                    :clearable="false"
                    :dateIcon="true"
                    modClass="modal-request"
                    :disabled="isReadonly"
                  />
                </div>
                <div class="coll coll-12 m-24">
                  <div class="label-field">Статус <span>*</span></div>
                  <SelectMultiComponent
                    v-model="item.statusId"
                    :options="statusList"
                    placeholder="Выберите"
                    modifier="rectangular"
                    required
                    :disabled="isReadonly"
                  />
                </div>
                <div class="coll coll-12 m-24">
                  <ComponentInput
                    label="Полное название комиссии <span>*</span>"
                    v-model="item.name"
                    required
                    maxLength="500"
                    type="textarea"
                    placeholder="Введите"
                    mod="rectangular"
                    :disabled="isReadonly"
                  />
                </div>
              </template>
            </div>
          </div>

          <div class="form-card__inner-card" v-if="item.countryId > 0">
            <div class="coll coll-6 coll-t-12 m-24">
              <CommissionParticipantBlock
                v-model="chairman"
                v-bind:isReadonly="isReadonly"
                v-bind:participantCategoryId="1"
                :isRG="isRG"
                v-bind:participantCategoryName="getParticipantCategoryName(1)"
                v-bind:countryId="countryRussiaId"
                v-bind:countryName="countryRussiaName"
                :showPhoto="true"
              />
            </div>
            <div class="coll coll-6 coll-t-12 m-24">
              <CommissionParticipantBlock
                v-model="chairmanForeign"
                v-bind:isReadonly="isReadonly"
                v-bind:participantCategoryId="1"
                :isRG="isRG"
                v-bind:participantCategoryName="getParticipantCategoryName(1)"
                v-bind:countryId="item.countryId"
                v-bind:countryName="getCountryName(item.countryId)"
                :showPhoto="true"
              />
            </div>
            <div class="coll coll-6 coll-t-12 m-24">
              <CommissionParticipantBlock
                v-model="coChairman"
                v-bind:isReadonly="isReadonly"
                v-bind:participantCategoryId="2"
                v-bind:participantCategoryName="getParticipantCategoryName(2)"
                v-bind:countryId="countryRussiaId"
                v-bind:countryName="countryRussiaName"
              />
            </div>
            <div class="coll coll-6 coll-t-12 m-24">
              <CommissionParticipantBlock
                v-model="coChairmanForeign"
                v-bind:isReadonly="isReadonly"
                v-bind:participantCategoryId="2"
                v-bind:participantCategoryName="getParticipantCategoryName(2)"
                v-bind:countryId="item.countryId"
                v-bind:countryName="getCountryName(item.countryId)"
              />
            </div>
            <div class="coll coll-6 coll-t-12 m-24">
              <CommissionParticipantBlock
                v-model="secretary"
                v-bind:isReadonly="isReadonly"
                v-bind:participantCategoryId="3"
                v-bind:participantCategoryName="getParticipantCategoryName(3)"
                v-bind:countryId="countryRussiaId"
                v-bind:countryName="countryRussiaName"
              />
            </div>
            <div class="coll coll-6 coll-t-12 m-24">
              <CommissionParticipantBlock
                v-model="secretaryForeign"
                v-bind:isReadonly="isReadonly"
                v-bind:participantCategoryId="3"
                v-bind:participantCategoryName="getParticipantCategoryName(3)"
                v-bind:countryId="item.countryId"
                v-bind:countryName="getCountryName(item.countryId)"
              />
            </div>
          </div>

          <div class="form-card__history" v-if="eventsFull.length">
            <div
              v-for="(item, index) in getEvent"
              :key="`${index}`"
              class="form-card__history-item coll coll-6 coll-m-12 m-24"
            >
              <h5 v-if="item.name || item.eventTypeName" class="title title--h5 title--history">
                {{ item.eventTypeName }}. {{ item.name || '' }}
              </h5>
              <p v-if="item.eventLastDate" class="form-card__history-date">
                {{ $momentFormat(item.eventLastDate, 'DD MMMM YYYY') }}
              </p>
              <p v-if="item.place" class="form-card__history-country">{{ item.place }}</p>
            </div>
            <div v-if="eventsFull.length > 4" class="coll coll-12 m-24 center">
              <ButtonComponent mod="more" @click.prevent.stop="showEvent">
                {{ showFullListEvent ? 'Скрыть' : 'Показать все исторические данные' }}
                <IconComponent class="icon--full-svg" name="plus-mini" />
              </ButtonComponent>
            </div>
          </div>

          <div v-if="isEdit && !isReadonly" class="coll coll-12">
            <ButtonComponent
              @click.prevent.stop="save"
              mod="gradient-bg"
              class="button-redesign--map-apply button-redesign--commission-form-edit"
              >Сохранить</ButtonComponent
            >
            <ButtonComponent @click.prevent.stop="close" class="button-redesign--commission-form-edit"
              >Отменить</ButtonComponent
            >
          </div>

          <div v-if="!isEdit && !isReadonly" class="coll coll-inner coll-12">
            <div class="coll coll-inner coll-6 coll-m-12">
              <div class="coll coll-6 coll-m-12 m-24">
                <ButtonComponent
                  @click.prevent.stop="save"
                  mod="gradient-bg"
                  class="button-redesign--map-apply button-redesign--commission-form"
                  >Сохранить</ButtonComponent
                >
              </div>
              <div class="coll coll-6 coll-m-12 m-24">
                <ButtonComponent @click.prevent.stop="close" class="button-redesign--commission-form"
                  >Отменить</ButtonComponent
                >
              </div>
            </div>
          </div>
        </form>
      </TabItem>
      <TabItem v-if="id > 0 && !isRG" name="Структура">
        <div class="commision-mpk__container" v-if="pageRg.items && pageRg.items.length">
          <div class="commision-mpk__table table-container js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th>Полное название рабочей группы (РГ)</th>
                  <th>Страна</th>
                  <th>Руководитель российской части РГ</th>
                  <th>Руководитель иностранной части РГ</th>
                  <th>Предыдущее заседание</th>
                  <th>Планируемое/текущее заседание</th>
                  <th v-if="canEdit"></th>
                  <th v-if="canEdit"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in pageRg.items" :key="item.id" @dblclick="editRg(item.id)">
                  <td>{{ item.name }}</td>
                  <td>{{ item.country.name }}</td>
                  <td>
                    <template v-if="item.chairman">
                      <span class="name">{{ item.chairman.name }}</span>
                      {{ item.chairman.position }}
                    </template>
                    <template v-else> (уточняется) </template>
                  </td>
                  <td>
                    <template v-if="item.chairmanForeign">
                      <span class="name">{{ item.chairmanForeign.name }}</span>
                      {{ item.chairmanForeign.position }}
                    </template>
                    <template v-else> (уточняется) </template>
                  </td>
                  <td>
                    <template v-if="item.lastEventZased">
                      <span class="date">{{ $momentFormat(item.lastEventZased.eventFirstDate, 'DD MMMM YYYY') }}</span>
                      {{ item.lastEventZased.place }}
                    </template>
                    <template v-else> (уточняется) </template>
                  </td>
                  <td>
                    <template v-if="item.nextEventZased">
                      <span class="date">{{ $momentFormat(item.nextEventZased.eventFirstDate, 'DD MMMM YYYY') }}</span>
                      {{ item.nextEventZased.place }}
                    </template>
                    <template v-else> (уточняется) </template>
                  </td>
                  <td v-if="canEdit">
                    <IconComponent @click.prevent.stop="editRg(item.id)" name="mode" />
                  </td>
                  <td v-if="canEdit">
                    <IconComponent @click.prevent.stop="deleteRg(item.id)" name="delete" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </TabItem>
      <TabItem name="Документы">
        <form action="/" class="form-card">
          <div class="coll coll-12 m-24">
            <h3 class="title title--h3 title--commission">Документы</h3>
          </div>
          <div class="coll coll-inner coll-12">
            <div class="coll coll-inner coll-6 coll-t-12">
              <div class="coll coll-12">
                <FileMultiUpload
                  :entity="item"
                  @deleteFile="removeFile"
                  prop-name="files"
                  @uploaded="fileUploaded"
                  :readonly="isReadonly"
                />
              </div>
            </div>
          </div>
          <div class="coll coll-inner coll-12">
            <div class="coll coll-inner coll-6 coll-m-12">
              <div class="coll coll-6 coll-m-12 m-24">
                <ButtonComponent
                  @click.prevent.stop="save"
                  mod="gradient-bg"
                  class="button-redesign--map-apply button-redesign--commission-form"
                  >Сохранить</ButtonComponent
                >
              </div>
              <div class="coll coll-6 coll-m-12 m-24">
                <ButtonComponent @click.prevent.stop="close" class="button-redesign--commission-form"
                  >Отменить</ButtonComponent
                >
              </div>
            </div>
          </div>
        </form>
      </TabItem>
    </Tab>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required, requiredIf } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import FileMultiUpload from '@/common/components/FileMultiUpload';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Tab from '@/common/components/redesigned/Tabs/Tab.vue';
  import TabItem from '@/common/components/redesigned/Tabs/TabItem.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import tab from '@/common/components/tabsDetailInner.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import API_ORG from '@/modules/admin/api/organization';

  import API from '../api/commission';
  import CommissionParticipantBlock from '../components/CommissionParticipantBlock';

  const selectValidator = (value) => value > 0;
  const selectIfRGValidator = (param) => (value) => {
    return !param || value > 0;
  };

  const TYPE_EVENT_ID_MEETINGS_MPK = 1;
  const TYPE_EVENT_ID_MEETINGS_CO_CHAIRS = 3;

  export default {
    name: 'CommissionCard',
    components: {
      ComponentInput,
      CommissionParticipantBlock,
      tab,
      FileMultiUpload,
      IconComponent,
      TabItem,
      Tab,
      SelectMultiComponent,
      ButtonComponent,
      dateComponent,
    },
    mixins: [security],
    data() {
      return {
        id: this.$route.params.id,
        isReadonly: this.$route.params.action === 'view',
        isEdit: this.$route.params.action === 'edit',

        isRG: this.$route.params.type === 'rg',
        type: this.$route.params.type === 'rg' ? 'rg' : 'mp',

        canEdit: false,
        item: {
          countryId: 0,
          foivCuratorId: null,
          statusId: '',
          parentId: '',
        },
        chairman: null,
        chairmanForeign: null,
        coChairman: null,
        coChairmanForeign: null,
        secretary: null,
        secretaryForeign: null,

        countryRussiaName: Constants.countryRussiaName,
        countryRussiaId: Constants.countryRussiaId,
        countryList: [],
        parentList: [],
        participantCategoryList: [],
        foivList: [],
        statusList: [],

        pageRg: {},
        events: [],
        eventsFull: [],

        errorCountryId: false,
        errorStatusId: false,
        errorParentId: false,
        // eventsList: [],
        showFullListEvent: false,
      };
    },
    created() {
      this.checkPermissions([Constants.Permissions.MpkCommissionEdit, Constants.Permissions.MpkCommissionAccess]);
      this.checkPermission(Constants.Permissions.MpkCommissionEdit).then((response) => {
        this.canEdit = response.data;
      });

      this.loadCard();

      Utils.loadMultiSelectOptions('countryList', this.countryList, { exceptId: this.countryRussiaId });
      Utils.loadMultiSelectOptions('mpkParticipantCategoryList', this.participantCategoryList);
      Utils.loadMultiSelectOptions('mpkStatusList', this.statusList);

      if (this.isRG) {
        API_ORG.searchShortFormat({ pageNumber: 1, pageSize: 0, filter: { typeIds: ['1'] } }).then((res) => {
          this.foivList = res.data.items.map((x) => ({ value: x.id, label: x.name }));
        });
      }
    },
    computed: {
      countryId() {
        return this.item.countryId;
      },
      statusId() {
        return this.item.statusId;
      },
      parentId() {
        return this.item.parentId;
      },

      getEvent() {
        if (this.showFullListEvent) {
          return this.eventsFull;
        }
        return this.events;
      },
    },
    watch: {
      countryId() {
        this.errorCountryId = false;
      },
      statusId() {
        this.errorStatusId = false;
      },
      parentId() {
        this.errorParentId = false;
      },
    },
    methods: {
      loadPageRg() {
        if (this.isRG || !(this.item.id > 0)) return;
        var params = {
          pageNumber: 1,
          pageSize: -1,
          hasParent: true,
          parentId: this.item.id,
        };

        API.search(params).then((response) => {
          this.pageRg = response.data;
        });
      },
      getParticipantCategoryName(id) {
        if (!(this.participantCategoryList.length > 0)) return '';
        var el = this.participantCategoryList.find((x) => +x.value === +id);
        if (this.isRG && id == 1) return 'Руководитель';
        return el ? el.label : '';
      },
      getCountryName(id) {
        if (!(this.countryList.length > 0)) return '';
        var el = this.countryList.find((x) => x.id == id);
        return el ? el.text : '';
      },
      changeCountry() {
        this.chairmanForeign = null;
        this.coChairmanForeign = null;
        this.secretaryForeign = null;

        //parentList
        if (this.isRG) {
          this.parentList = [];
          this.item.parentId = 0;
          API.searchForList({ hasParent: false, countryId: this.item.countryId })
            .then((response) => {
              if (response.data && response.data.items) {
                response.data.items.map((x) => this.parentList.push({ value: x.id, label: x.name }));
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      },
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;

            if (response.data?.status?.id) {
              this.item.statusId = response.data?.status.id;
            }
            if (response.data?.createdInfo || response.data?.created) {
              this.item.createdInfo = response.data?.createdInfo || response.data?.created || '';
            }
            this.chairman = this.item.mpkParticipants.find(
              (x) => x.participant.participantCategoryId == 1 && x.participant.countryId == this.countryRussiaId,
            );
            this.chairmanForeign = this.item.mpkParticipants.find(
              (x) => x.participant.participantCategoryId == 1 && x.participant.countryId != this.countryRussiaId,
            );
            this.coChairman = this.item.mpkParticipants.find(
              (x) => x.participant.participantCategoryId == 2 && x.participant.countryId == this.countryRussiaId,
            );
            this.coChairmanForeign = this.item.mpkParticipants.find(
              (x) => x.participant.participantCategoryId == 2 && x.participant.countryId != this.countryRussiaId,
            );
            this.secretary = this.item.mpkParticipants.find(
              (x) => x.participant.participantCategoryId == 3 && x.participant.countryId == this.countryRussiaId,
            );
            this.secretaryForeign = this.item.mpkParticipants.find(
              (x) => x.participant.participantCategoryId == 3 && x.participant.countryId != this.countryRussiaId,
            );

            if (this.chairman) this.chairman = this.chairman.participant;
            if (this.chairmanForeign) this.chairmanForeign = this.chairmanForeign.participant;
            if (this.coChairman) this.coChairman = this.coChairman.participant;
            if (this.coChairmanForeign) this.coChairmanForeign = this.coChairmanForeign.participant;
            if (this.secretary) this.secretary = this.secretary.participant;
            if (this.secretaryForeign) this.secretaryForeign = this.secretaryForeign.participant;

            if (!this.isRG) this.loadPageRg();

            this.processEvents(this.item.events);
          });
        } else {
          this.item = {
            isActive: true,
            countryId: 0,
            mpkParticipants: [],
            parentId: this.isRG ? 0 : null,
            files: [],
          };

          if (this.$route.query && this.$route.query.countryId > 0) this.item.countryId = this.$route.query.countryId;
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.item.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');

          if (!this.countryId) {
            this.errorCountryId = true;
          }
          if (!this.statusId) {
            this.errorStatusId = true;
          }

          if (this.isRG && !this.parentId) {
            this.errorParentId = true;
          }
          return;
        }

        var itemToSave = Object.assign({}, this.item);
        itemToSave.participants = [
          this.chairman,
          this.chairmanForeign,
          this.coChairman,
          this.coChairmanForeign,
          this.secretary,
          this.secretaryForeign,
        ];

        itemToSave.mpkCommissionDocumentIds = itemToSave.files.map((x) => x.id);
        itemToSave.files = null;
        itemToSave.events = null;
        itemToSave.mpkParticipants = null;

        API.update(itemToSave)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data.id) {
              this.$router.push({
                name: 'CommissionCard',
                params: { id: response.data.id, action: 'edit', type: this.type },
              });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Commissions', params: { type: this.type } });
      },
      fileUploaded(e) {
        this.item.files.push(e.data);
      },
      removeFile(e) {
        var file = this.item.files.find((x) => x.id == e.id);
        var index = this.item.files.indexOf(file);
        if (index !== -1) {
          this.item.files.splice(index, 1);
        }
      },
      processEvents(arr) {
        if (arr && arr.length) {
          this.eventsFull = arr.filter(
            (i) => +i.eventTypeId === TYPE_EVENT_ID_MEETINGS_MPK || +i.eventTypeId === TYPE_EVENT_ID_MEETINGS_CO_CHAIRS,
          );
          this.events = this.eventsFull.slice(0, 4);
        }
      },
      editRg(id) {
        this.$router.push({
          name: 'CommissionCard',
          params: { id: id, type: 'rg', action: this.canEdit ? 'edit' : 'view' },
        });
      },
      deleteRg(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPageRg();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data
                    ? error.response.data
                    : 'Запись не удалена. Она используется в других реестрах.',
                  'error',
                );
              });
          }
        });
      },

      showEvent() {
        this.showFullListEvent = !this.showFullListEvent;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {
          name: { required },
          countryId: { required, selectValidator },
          statusId: { required, selectValidator },
          parentId: {
            required: requiredIf(function () {
              return this.isRG;
            }),
            selectIfRGValidator: selectIfRGValidator(this.isRG),
          },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  @import '@/modules/crossCuttingTask/styles/coll.scss';

  .form-card {
    margin-top: 40px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    padding: 24px 16px 0;
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 50px;

    &__country {
      display: flex;
      align-items: center;

      &-img {
        width: 40px;
        height: 24px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 8px;
      }
    }

    &__history {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 24px;
      border-top: 1px dashed $grey;

      .hidden {
        display: none;
      }

      p {
        font: $fira-12;
        margin-bottom: 4px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &__parent {
      letter-spacing: -0.4px;
      font: $fira-20;
    }

    &__inner-card {
      width: 100%;
      padding-top: 24px;
      border-top: 1px dashed $grey;
      display: flex;
      flex-wrap: wrap;
    }

    @media (max-width: $laptop) {
      margin-top: 24px;
      padding: 20px 8px 8px;
      margin-bottom: 0;

      &__inner-card {
        padding-top: 16px;
      }

      &__history {
        padding-top: 16px;
      }
    }
  }

  .commision-mpk {
    &__container {
      margin-top: 40px;
    }

    @media (max-width: $laptop) {
      &__container {
        margin-top: 24px;
      }
    }
  }
  .card-info {
    &__row {
      flex-wrap: wrap;
      .control-label,
      .title {
        font-family: 'Fira Sans', sans-serif;
        font-size: 14px;
        line-height: 160%;
      }
      .title {
        font-weight: 500;
        color: #343c44;
      }
      .control-label {
        font-weight: 400;
        color: #231f20;
      }
      .title,
      .container-block {
        width: 100%;
      }
      .container-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }
      &.btns {
        justify-content: flex-end;
        button {
          &:not(:last-of-type) {
            margin-right: 16px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .card-info__row .container-block {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 767px) {
    .card-info__row.btns button {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 0px;
        margin-bottom: 16px;
      }
    }
  }

  .imagePreviewWrapper {
    width: 48px;
    height: 24px;
    display: inline-block;
    float: left;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
  }
</style>
